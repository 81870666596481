import { classNames } from "~/lib/classNames";
import { useUIState } from "~/ui/UIState";
import styles from "./BurgerButton.module.css";

interface Props {
  controls: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BurgerButton = (props: Props) => {
  const onClick = () => {
    props.setOpen?.(!props.open);
  };
  return (
    <button
      type="button"
      class={classNames(styles["burger-button"], props.open ? styles["burger-button--open"] : "")}
      title="Open the main menu."
      aria-controls={props.controls}
      aria-expanded={props.open}
      onClick={onClick}
    >
      <span class={classNames(styles["burger-button__line"], styles["burger-button__line--top"])} />
      <span class={classNames(styles["burger-button__line"], styles["burger-button__line--middle"])} />
      <span class={classNames(styles["burger-button__line"], styles["burger-button__line--bottom"])} />
      <span class="screen-reader">Open the main menu.</span>
    </button>
  );
};
